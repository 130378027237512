import { Popover } from 'antd'
import { InfoCircleTwoTone } from '@ant-design/icons'

export const TeamChannelTableColumn = ({ provider = 'github' }) => {
  return (
    <thead className="bg-gray-50">
      <tr>
        <th
          scope="col"
          className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
        >
          Channel{' '}
          <Popover
            content={
              <div className="max-w-[400px]">
                <p>
                  For private channels, you need to invite the Axolo bot to the channel
                  first. You can do this by typing @axolo in your Slack channel and Slack
                  will ask if you want to invite the bot.
                </p>
                <a
                  href="https://help.axolo.co/en/article/how-to-use-a-private-channel-for-axolo-team-channel-1fi37c0/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-2 block text-blue-600 hover:text-blue-800"
                >
                  Learn more
                </a>
              </div>
            }
          >
            <InfoCircleTwoTone twoToneColor="#0049ff" />
          </Popover>
        </th>
        {provider === 'github' && (
          <th
            scope="col"
            className="!w-[100px] py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
          >
            Option
          </th>
        )}
        <th
          scope="col"
          className="w-[500px] px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
        >
          {provider === 'github' ? 'Repo or Teams' : 'Projects'}
        </th>
        <th
          scope="col"
          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
        >
          Creator
        </th>

        <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-6">
          <span className="sr-only">Action</span>
        </th>
      </tr>
    </thead>
  )
}
